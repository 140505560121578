/**
 * Removes wr-loader
 */
const remove = (callback) => {
	const loader = document.querySelector('.js-wr-loader');
	loader && loader.remove();

	if (callback) {
		callback();
	}
}

const show = () => {
	// Remove any loaders if for any reason duplicates exist
	remove();

	// Show loader
	const loader = document.createElement('div');
	loader.classList.add('wr-loader','js-wr-loader');
	loader.innerHTML = '<div class="wr-loader__spinner"></div><div class="wr-loader__bg"></div>';
	document.body.append(loader);
}

export default {
	remove,
	show
}