import { useEffect, useState, createRef } from "react";
import axios from "axios";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { v4 as uuidv4 } from "uuid";
import "./Form.scss";
import loader from "../../scripts/loader";

function Form() {
  const [messageType, setMessageType] = useState("individual");
  const [messageName, setMessageName] = useState("");
  const [message, setMessage] = useState("");
  const refForm = createRef();
  const refMessage = createRef();
  const refFirstName = createRef();
  const refLastName = createRef();
  const refFamilyName = createRef();
  const refImage = createRef();
  const refCanvas = createRef();
  const [messageSuccess, setMessageSuccess] = useState(false);

  // Handles creating and updating the message name
  const createMessageName = () => {
    // Create message name
    const newMessageName =
      messageType === "individual"
        ? `${refFirstName.current.value} ${refLastName.current.value}`
        : refFamilyName.current.value;

    // Set message information
    setMessageName(newMessageName);
  };

  // Handles updating the message
  const createMessage = () => {
    // Create message
    setMessage(refMessage.current.value);
  };

  useEffect(() => {
    // console.log(`Message Type: ${messageType}`);
    // console.log(">>> REDRAWN");
  });

  if (messageSuccess) {
    return (
      <Row>
        <Col className="text-center">
          <hr />
          <h2>Your message is received.</h2>
          <p className="thank-you">
            We, the Obijiaku family would like to thank you for your kind
            message and support during this period.
          </p>

          <Button
            onClick={() => {
              window.location.reload();
            }}
          >
            Send another message
          </Button>
        </Col>
      </Row>
    );
  }

  return (
    <form className="form" ref={refForm}>
      <Row>
        <Col xs={12} sm={6}>
          <input
            defaultChecked
            className="radio"
            type="radio"
            name="messageType"
            value="individual"
            onChange={(event) => setMessageType(event.target.value)}
          />
          <strong>Individual</strong>
        </Col>
        <Col xs={12} sm={6}>
          <input
            className="radio"
            type="radio"
            name="messageType"
            value="family"
            onChange={(event) => setMessageType(event.target.value)}
          />
          <strong>Family</strong>
        </Col>
      </Row>

      {(() => {
        if (messageType === "individual") {
          return (
            <>
              <Row className="field-row">
                <Col xs={12} sm={3}>
                  <label>First Name:</label>
                </Col>
                <Col xs={12} sm={9}>
                  <input
                    onChange={createMessageName}
                    type="text"
                    id="firstName"
                    name="firstName"
                    placeholder="First Name"
                    ref={refFirstName}
                  />
                </Col>
              </Row>
              <Row className="field-row">
                <Col xs={12} sm={3}>
                  <label>Last Name:</label>
                </Col>
                <Col xs={12} sm={9}>
                  <input
                    onChange={createMessageName}
                    type="text"
                    id="lastName"
                    name="lastName"
                    placeholder="Last Name"
                    ref={refLastName}
                  />
                </Col>
              </Row>
            </>
          );
        }

        return (
          <Row className="field-row">
            <Col xs={12} sm={3}>
              <label>Family Name:</label>
            </Col>
            <Col xs={12} sm={9}>
              <input
                onChange={createMessageName}
                id="familyName"
                type="text"
                placeholder="Family Name Here..."
                ref={refFamilyName}
              />
            </Col>
          </Row>
        );
      })()}

      <Row className="field-row">
        <Col xs={12} sm={3}>
          <label>Message:</label>
        </Col>
        <Col xs={12} sm={9}>
          <textarea
            onChange={createMessage}
            placeholder="Enter your message here..."
            ref={refMessage}
          ></textarea>
        </Col>
      </Row>

      <Row className="field-row">
        <Col xs={12} sm={3}>
          <label>Photo Upload:</label>
        </Col>
        <Col xs={12} sm={9}>
          <input
            id="fileupload"
            ref={refImage}
            type="file"
            accept="image/x-png,image/gif,image/jpeg"
            onChange={(event) => {
              console.log(">>> changed");

              const file = refImage.current.files[0];

              // Create image
              let img = document.createElement("img");

              img.src = window.URL.createObjectURL(file);
              img.onload = (event) => {
                let canvas = document.querySelector("#photo");
                let ctx = canvas.getContext("2d");

                // Clears canvas
                canvas.width = canvas.width;

                // Draw photo
                ctx.drawImage(img, 0, 0, 100 * (img.width / img.height), 125);
              };

              document.querySelector("#hiddenPhotos").appendChild(img);
            }}
          />
        </Col>
      </Row>

      <Row>
        <Col>
          <canvas id="photo" ref={refCanvas}></canvas>
          <div id="hiddenPhotos"></div>
        </Col>
      </Row>

      <Row className="form-submit">
        <Col>
          <Button
            onClick={async (event) => {
              event.preventDefault();

              // Check form values
              if (messageType === "individual") {
                let validationMessage = "";

                // Check first name
                if (!refFirstName.current.value) {
                  validationMessage = "You must enter a first name.";
                }

                // Check last name
                if (!refLastName.current.value) {
                  validationMessage === ""
                    ? (validationMessage = "You must enter a last name.")
                    : (validationMessage = `${validationMessage}\nYou must enter a last name.`);
                }

                // Check message
                if (!refMessage.current.value) {
                  validationMessage === ""
                    ? (validationMessage = "You must enter a message.")
                    : (validationMessage = `${validationMessage}\nYou must enter a message.`);
                }

                if (validationMessage) return alert(validationMessage);
              } else {
                let validationMessage = "";

                // Family message
                if (!refFamilyName.current.value) {
                  validationMessage = "You must enter a family name.";
                }

                // Check message
                if (!refMessage.current.value) {
                  validationMessage === ""
                    ? (validationMessage = "You must enter a message.")
                    : (validationMessage =
                        "You must enter a family name and message.");
                }

                if (validationMessage) return alert(validationMessage);
              }

              // Show spinner
              loader.show();

              // File (image) information
              let file, fileExtension, fileName;
              if (refImage.current.value) {
                file = refImage.current.files[0];
                fileExtension = file.name.split(".")[1];
                fileName = `${uuidv4()}.${fileExtension}`;
              }

              const messageId = uuidv4();
              const reader = new FileReader();

              if (!refImage.current.value) {
                // Send message without photo
                console.log(">>> NO PHOTO VALUE");

                // Get pre-signed url
                axios
                  .post(
                    "https://es7t3iiyuh.execute-api.us-east-1.amazonaws.com/default/MessagePutObijiaku",
                    {
                      message,
                      messageId,
                      messageName,
                    }
                  )
                  .then((res) => {
                    // Set message success
                    setMessageSuccess(true);

                    // Remove loader
                    loader.remove();
                  })
                  .catch((error) => {
                    // Remove loader
                    loader.remove();
                  });
              } else {
                // Send message with photo

                // After the file is read
                reader.onload = (e) => {
                  // Append read result
                  const readFile = e.target.result;

                  // Get pre-signed url
                  axios
                    .post(
                      "https://es7t3iiyuh.execute-api.us-east-1.amazonaws.com/default/MessagePutObijiaku",
                      {
                        fileName: fileName,
                        message,
                        messageId,
                        messageName,
                      }
                    )
                    .then((res) => {
                      console.log(">>> SUCCESSFULL RESPONSE");
                      console.log(res);
                      const data = JSON.parse(res.data.body);
                      const uploadURL = data.uploadURL;

                      console.log(">>> RUNNING UPLOAD IMAGE ATTEMPT");
                      console.log(data);

                      // Put image to s3
                      axios.defaults.headers.put["Content-Type"] = "image/jpeg";
                      axios
                        .put(uploadURL, readFile)
                        .then((res) => {
                          // Set message success
                          setMessageSuccess(true);

                          // Remove loader
                          loader.remove();
                        })
                        .catch((error) => {
                          console.log(">>> Error uploading image");
                          loader.remove();
                        });
                    })
                    .catch((error) => {
                      console.log(">>> Error getting presigned url");
                      loader.remove();
                    });
                };

                // Read uploaded file
                reader.readAsArrayBuffer(file);
              }
            }}
          >
            Submit Message
          </Button>
        </Col>
      </Row>
    </form>
  );
}

export default Form;
