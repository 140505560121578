import Container from "react-bootstrap/Container";
import Carousel from "react-bootstrap/Carousel";
import Form from "./components/Form/Form";
import "./App.scss";
import slide01 from "../src/images/slide-01.jpg";
import { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import axios from "axios";
import loader from "./scripts/loader";

function App() {
  const [showMessages, setShowMessages] = useState(false);
  const [messages, setMessages] = useState([]);

  useEffect(() => {
    // Check for search messages
    const search = window.location.search && window.location.search.split("=");

    if (search.length) {
      setShowMessages(search[1]);

      axios
        .get(
          "https://es7t3iiyuh.execute-api.us-east-1.amazonaws.com/default/messagegetobijiaku"
        )
        .then((res) => {
          // Set message success
          const messages = JSON.parse(res.data.body.data);
          setMessages(messages);
          // setMessageSuccess(true);

          // Remove loader
          loader.remove();
        })
        .catch((error) => {
          // Remove loader
          loader.remove();
        });
    }
  }, []);
  return (
    <Container fluid>
      <header className="App-header">
        <h1>Ichie Chuka Nathan Obijiaku</h1>
      </header>
      {`${showMessages}`.toLowerCase() === "true" && (
        <>
          <Container className="view-messages-container">
            <div className="view-messages">
              {messages.map((message) => {
                return (
                  <div className="view-message" key={message.message_id}>
                    <div className="view-message__photo">
                      <img
                        src={`https://obijiaku-images.s3.amazonaws.com/images/${message.photo}`}
                      />
                    </div>
                    <div className="view-message__message">
                      <div className="view-message__message-title">
                        {message.message_name}
                      </div>
                      <div className="view-message__message-data">
                        {message.message}
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          </Container>
        </>
      )}
      {`${showMessages}`.toLowerCase() !== "true" && (
        <>
          <section className="car">
            <div className="car-container">
              <Carousel fade>
                <Carousel.Item>
                  <img
                    className="d-block w-100"
                    src={slide01}
                    alt="First slide"
                  />
                  <Carousel.Caption>
                    <h3>Chuka Obijiaku</h3>
                    <p>Chuka Objijiaku with loving wife Ada.</p>
                  </Carousel.Caption>
                </Carousel.Item>
              </Carousel>
            </div>
          </section>
          <Container>
            <main>
              <p className="text--alt">
                <strong>Chuka Obijiaku</strong>, affectionately known as{" "}
                <strong>Okpete</strong>, was one of the Igbo communities'
                strongest leaders in Los Angeles and worldwide.
              </p>
              <p className="text--alt">
                He is survived by his adoring wife Adagu (Oji Ugo), his children
                Obinna, Adaobi, and Njideka, his grandchildren Amarachi and
                Nneoma and Sibilings Chiedu Obijiaku, Phoebe Nwankpa, Patience
                Umeh.
              </p>
              <p className="text--alt">
                Our beloved Father passed away in peace at Kaiser Hospital on
                Friday, February 25, 2022.
              </p>
              <hr />
              <p>
                If you would like to leave a message for the Obijiaku Family you
                may do so via the form below:
              </p>
              <Form />
            </main>
          </Container>
        </>
      )}
    </Container>
  );
}

export default App;
